import { Session } from 'next-auth';

import { getMobilePlans as getPlansService } from '@services/products';

import { ContractType } from '@app-types/contract';
import { FundingType } from '@app-types/funding';
import { MobilePlanType } from '@app-types/plan';
import { GalliumType } from '@app-types/renewal';

import { PlanTypeId } from '@constants/plan';

export function getMobilePlans(
    setFunding: (funding?: FundingType) => void,
    setPlans: (plans: MobilePlanType[]) => void,
    setCurrentPlan: (plan: MobilePlanType) => void,
    contract?: ContractType,
    session?: Session,
    includeGenericBboxSapicBody?: boolean,
) {
    return async (
        currentProductGencode: string,
        planTypeId: PlanTypeId,
        isRenewal: boolean,
        cartPlanGencode?: string,
        renewalData?: GalliumType,
    ) => {
        const detainedPlanGencode =
            isRenewal && contract && renewalData
                ? cartPlanGencode || renewalData[contract.id]?.opSource.idOffre
                : cartPlanGencode;

        getPlansService(
            currentProductGencode,
            isRenewal,
            planTypeId,
            contract?.id,
            detainedPlanGencode,
            session,
            includeGenericBboxSapicBody,
            false,
        ).then((plans) => {
            setPlans(plans);
            const newCurrentPlan = plans.find((plan) => plan.gencode === detainedPlanGencode);

            if (newCurrentPlan) {
                setCurrentPlan(newCurrentPlan);
                const funding = newCurrentPlan?.fundings.find((f) => f.favorite);
                setFunding(funding);
            }
        });
    };
}
