import { getLocalVariable, removeLocalVariable } from '@services/storage';

import { StorageCartType } from '@app-types/api/cart';
import { QUOTE_TYPES, QuoteType } from '@app-types/cart';

import { quoteMapper } from '@helpers/cart';

const CART_STORAGE_KEY = 'checkout_cart';

export function getCart(isRenewal: boolean = false): QuoteType | undefined {
    const cartStorage = getLocalVariable(CART_STORAGE_KEY);
    const cart = JSON.parse(cartStorage || '{}') as StorageCartType;
    if (cart) {
        const quoteMobile = isRenewal ? QUOTE_TYPES.RENEWAL : QUOTE_TYPES.ACQUISITION;
        const checkoutQuote = cart.data?.quotes.find((quote) => quote.context.id === quoteMobile);
        if (checkoutQuote) {
            return quoteMapper(checkoutQuote);
        }
    }
    return undefined;
}

export function isPro(isRenewal: boolean = false): boolean {
    const cartStorage = getLocalVariable(CART_STORAGE_KEY);
    const cart = JSON.parse(cartStorage || '{}') as StorageCartType;

    const isCartPro = cart.data?.isPro ?? false;
    const hasOneProductPro = getCart()?.products.some((product) => product.isPro) ?? false;

    return (hasOneProductPro || isCartPro) && !isRenewal;
}

export function getContractCart(isRenewal: boolean = false, contractId?: string): QuoteType | undefined {
    const cart = getCart(isRenewal);
    const contractInCart = cart?.context?.contractId;
    if (contractId !== contractInCart) {
        removeLocalVariable(CART_STORAGE_KEY);
        return undefined;
    } else {
        return cart;
    }
}
