import { ApiDeliveryModesType, DeliveryModesType } from '@app-types/product-delivery';

export class SapiMapper {
    static mapToDeliveryModes(apiData: ApiDeliveryModesType): DeliveryModesType {
        return apiData.modesLivraison.map((mode) => ({
            id: mode.gencode,
            estimatedDeliveryDate: mode.dateLivraisonEstimee,
            price: mode.prix,
        }));
    }
}
