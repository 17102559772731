import { getAppConfig } from '@services/config';
import { apiHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { QUOTE_TYPES } from '@app-types/cart';
import { ProductNotFoundError } from '@app-types/error';
import { ApiDeliveryModesType } from '@app-types/product-delivery';
import { UserType } from '@app-types/user';

class SapiRepository {
    url: string;

    public constructor(url: string) {
        this.url = url;
    }

    public getProductByGencode<T>(gencode: string): Promise<T> {
        return this.getProducts(`/${gencode}`);
    }

    public async getProductByUrl<T>(url: string, withParent: boolean = false): Promise<T> {
        const params = { attribute: 'url_key', parent: withParent ? 'true' : 'false' };

        return this.getProducts(`/${url}?${new URLSearchParams(params)}`);
    }

    public async getProductsByGencodes<T>(
        gencodes: string[],
        options?: {
            withChildren?: boolean;
        },
        user?: UserType,
    ): Promise<T> {
        if (gencodes.length === 0) {
            throw new Error('No gencodes provided');
        }

        let optionsString = '';

        if (options?.withChildren === false) {
            optionsString += `&withChildren=false`;
        }

        return this.getProducts(`?listGencode=${gencodes.join(',')}${optionsString}`, user);
    }

    public async getProducts<T>(url: string, user?: UserType): Promise<T> {
        return apiHttpService
            .get<T>(`${this.url}/ventes/produits${url}`, undefined, {
                ttl: parseInt(getAppConfig().cache.ttl.productDetail),
                tags: [CACHE_TAGS.PRODUCT_DETAIL],
                user,
            })
            .catch((error) => {
                if (error?.response?.status === 404) {
                    throw new ProductNotFoundError(`Product not found ${url}`);
                } else {
                    throw error;
                }
            });
    }

    public async getDeliveryModes(gencode: string, userZipCode = '75000'): Promise<ApiDeliveryModesType> {
        const deliveryParams = {
            typeParcours: QUOTE_TYPES.ACQUISITION,
            typeClient: 'GP',
            gencodes: [gencode],
            adresse: { codePostal: userZipCode },
        };

        return apiHttpService.post<ApiDeliveryModesType>(`${this.url}/ventes/paniers/modes-livraison`, deliveryParams, {
            ttl: parseInt(getAppConfig().cache.ttl.products),
            tags: [CACHE_TAGS.PRODUCT_DETAIL],
        });
    }
}

export const sapiRepository = new SapiRepository(getAppConfig().sapi.url);
