import { getAppConfig } from '@services/config';
import { externalHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

import { ApiStockType, StockType } from '@app-types/product-delivery';

const appConf = getAppConfig();

export const getProductStock = async (gencode: string): Promise<StockType> => {
    return externalHttpService
        .get<ApiStockType>(`${appConf.smartTraffik.url}/product/${gencode}/status`, undefined, {
            headers: { 'api-key': appConf.smartTraffik.apikey },
            ttl: parseInt(getAppConfig().cache.ttl.productsStocks),
            tags: [CACHE_TAGS.PRODUCTS_STOCK, CACHE_TAGS.PRODUCTS],
        })
        .then((response): StockType => {
            if (response) {
                const { active, activeEreservation, activePreReservation } = response;
                return {
                    isEresa: active === '1' && activeEreservation === '1' && activePreReservation !== '1',
                    isPreresa: active === '1' && activePreReservation === '1',
                };
            }
            throw new Error('Empty response');
        })
        .catch(() => {
            return {
                isEresa: false,
                isPreresa: false,
            };
        });
};
