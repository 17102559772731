import { ProductChildFull, ProductParentFull, ProductType } from '@app-types/product';

import { stringToNumber } from '@helpers/number';
import { capitalize, formatPrice, isFirstLetterVowel } from '@helpers/wording';

const answerGenerators = {
    size: (definiteArticle: string, name: string, length: string, width: string, height: string): string =>
        `${capitalize(
            definiteArticle,
        )}${name} mesure ${length}cm de long pour ${width} cm de large. Son épaisseur est quant à elle de ${height} cm. ${
            stringToNumber(height) < 0.8
                ? 'Cette faible épaisseur le rend particulièrement fin et donc agréable à prendre en main.'
                : ''
        }`,
    weight: (definiteArticle: string, name: string, weight: string): string =>
        `${capitalize(definiteArticle)}${name} pèse ${weight} g. ${
            stringToNumber(weight) < 160 ? 'A noter que cela fait de lui un smartphone plus léger que la moyenne.' : ''
        }`,
    screen: (definiteArticle: string, name: string, screenTechnology: string, diagonal: string): string =>
        `${capitalize(definiteArticle)}${name} est doté d’un écran ${screenTechnology} de ${diagonal} pouces. ${
            stringToNumber(diagonal) < 6 ? 'Cette taille le classe dans la catégorie des smartphones compacts.' : ''
        } ${stringToNumber(diagonal) > 6.5 ? 'Cette taille le classe dans la catégorie des grands écrans.' : ''}`,
    battery: (definiteArticle: string, name: string, batteryCapacity: string): string =>
        `${capitalize(definiteArticle)}${name} est doté d’une batterie de ${batteryCapacity} mAh. ${
            stringToNumber(batteryCapacity) > 4000
                ? 'Il s’agit d’une capacité particulièrement élevée, offrant une bonne autonomie.'
                : ''
        }`,
    color: (definiteArticle: string, name: string, colorsAvailable: string[]): string =>
        `Sur notre site, ${definiteArticle}${name} est commercialisé dans ${
            colorsAvailable.length === 1 ? 'la couleur suivante' : 'les couleurs suivantes'
        }\u00A0: ${colorsAvailable.join(', ')}.`,
    cost: (definiteArticle: string, name: string, price: number): string =>
        `En dehors de toute remise, ${definiteArticle}${name} est disponible à partir de ${formatPrice(price)}. Mais en plus d’éventuelles promotions, choisir un ${name} avec un forfait Bouygues Telecom 130 Go ou plus vous permet de profiter d’une remise très avantageuse sur ce modèle (engagement 24 mois)\u00A0!`,
};

export type FaqType = {
    question: string;
    answer: string;
}[];

type FaqConfigType = {
    [key: string]: {
        isAvailable: (product: ProductParentFull | ProductChildFull) => boolean;
        question: string;
        generateAnswer: (product: ProductParentFull | ProductChildFull) => string;
    };
};
export const getProductFAQ = (product: ProductParentFull, productChild: ProductChildFull): FaqType => {
    const { name } = product;
    const definiteArticle = isFirstLetterVowel(name) ? "l'" : 'le ';
    const partitiveArticle = isFirstLetterVowel(name) ? "de l'" : 'du ';

    const faqConfig: FaqConfigType = {
        size: {
            isAvailable: (product) =>
                'characteristics' in product &&
                !!(product.characteristics.length && product.characteristics.width && product.characteristics.height),
            question: `Quelle est la taille ${partitiveArticle}${name} ?`,
            generateAnswer: (product: ProductParentFull | ProductChildFull) =>
                'characteristics' in product
                    ? answerGenerators.size(
                          definiteArticle,
                          name,
                          product.characteristics.length as string,
                          product.characteristics.width as string,
                          product.characteristics.height as string,
                      )
                    : '',
        },
        weight: {
            isAvailable: (product) => 'characteristics' in product && !!product.characteristics.weight,
            question: `Quel est le poids ${partitiveArticle}${name}\u00A0?`,
            generateAnswer: (product) =>
                'characteristics' in product
                    ? answerGenerators.weight(definiteArticle, name, product.characteristics.weight as string)
                    : '',
        },
        screen: {
            isAvailable: (product) => 'characteristics' in product && !!product.characteristics.diagonal,
            question: `De quel écran est équipé ${definiteArticle}${name}\u00A0?`,
            generateAnswer: (product) =>
                'characteristics' in product
                    ? answerGenerators.screen(
                          definiteArticle,
                          name,
                          product.characteristics.screenTechnology ?? '',
                          product.characteristics.diagonal as string,
                      )
                    : '',
        },
        battery: {
            isAvailable: (product) => 'characteristics' in product && !!product.characteristics.battery?.capacity,
            question: `Quelle est l’autonomie ${partitiveArticle}${name}\u00A0?`,
            generateAnswer: (product) =>
                'characteristics' in product
                    ? answerGenerators.battery(
                          definiteArticle,
                          name,
                          product.characteristics.battery?.capacity as string,
                      )
                    : '',
        },
        color: {
            isAvailable: (product) => 'features' in product && product.features.color.length > 0,
            question: `Quelles sont les différentes couleurs ${partitiveArticle}${name}\u00A0?`,
            generateAnswer: (product) =>
                'features' in product
                    ? answerGenerators.color(
                          definiteArticle,
                          name,
                          product.features.color.map(({ name }) => name),
                      )
                    : '',
        },
        cost: {
            isAvailable: (product) =>
                'prices' in product &&
                'type' in product &&
                product.prices.initial > 0 &&
                product.type === ProductType.PHONE,
            question: `Combien coûte ${definiteArticle}${name}\u00A0?`,
            generateAnswer: (product) => answerGenerators.cost(definiteArticle, name, product.prices.initial),
        },
    };

    return Object.entries(faqConfig).reduce((faq, [key, { isAvailable, question, generateAnswer }]) => {
        const productToPass = key === 'color' ? product : productChild;
        if (isAvailable(productToPass)) {
            faq.push({ question, answer: generateAnswer(productToPass) });
        }
        return faq;
    }, [] as FaqType);
};
