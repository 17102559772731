import { PlanPrice } from '@services/products';

import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { ProductChildFull } from '@app-types/product';

import { isComptant } from '@helpers/funding';
import { getDiscountValue } from '@helpers/product';

export type PricesDetails = {
    productPrices: {
        marketingAmount: number;
        fundingMonthlyAmount: number;
        todayPrice: number;
        odrRefund: number;
    };
    planPrices: {
        mainAmount: number;
        forever: number;
        promoDuration?: number;
    };
    funding?: {
        type: FundingEnumType;
        installmentCount: number;
        label: string;
    };
};

export function getPricesDetails(
    currentProduct?: ProductChildFull,
    selectedFunding?: FundingType,
    planPrice?: PlanPrice,
): PricesDetails {
    const fixedPriceTTC = currentProduct?.details?.fundings?.find((f) => isComptant(f.type))?.amountToFinance ?? 0;
    const odrRefundTTC = getDiscountValue(currentProduct?.details?.discounts ?? [], DiscountEnumType.ODR) ?? 0;

    const productMainAmountTTC =
        (selectedFunding ? selectedFunding.initialContributionWithOdr : fixedPriceTTC - odrRefundTTC) ?? 0;

    const todayProductPriceTTC =
        (selectedFunding?.initialDeposit ? selectedFunding.initialDeposit : fixedPriceTTC) ?? 0;

    const fundingMonthlyAmountTTC = selectedFunding?.monthlyAmount ?? 0;

    const foreverPlanPrice = planPrice?.forever ?? 0;
    const planPriceToShow = planPrice?.final ?? 0;
    const odrRefund = odrRefundTTC * -1;

    return {
        productPrices: {
            marketingAmount: productMainAmountTTC,
            fundingMonthlyAmount: fundingMonthlyAmountTTC,
            todayPrice: todayProductPriceTTC,
            odrRefund,
        },
        planPrices: {
            mainAmount: planPriceToShow,
            forever: foreverPlanPrice,
            promoDuration: planPrice?.promoDuration,
        },
        ...(selectedFunding && {
            funding: {
                type: selectedFunding.type,
                installmentCount: selectedFunding.installmentCount,
                label: selectedFunding.label,
            },
        }),
    };
}
