import { IconName } from '@bytel/trilogy-assets/lib/iconNameEnum';

import { IconColor } from '@trilogy-ds/react/lib/components/icon/IconEnum';
import { ItemAvailability } from 'schema-dts';

export type StockConfigType = {
    status: STOCK_STATUS;
    icon: IconName;
    altIcon: IconName;
    color: IconColor;
    label: string;
    subLabel?: string;
    cart: string;
    hasShipping: boolean;
    hasCnC: boolean;
    hasStock: boolean;
    structuredData: ItemAvailability;
};

export enum SHIPPING_MODES {
    CLICK_AND_COLLECT = 'CLICK_COLLECT',
    RELAIS_EXPRESS = 'POINT_RELAIS_EXPRESS',
    RELAIS_COLIS = 'POINT_RELAIS',
    LA_POSTE = 'LA_POSTE',
    CHRONOPOST = 'CHRONOPOST',
    INSTALLER = 'INSTALLATEUR',
    DIRECT = 'LIVRAISON_DIRECTE',
    LIVRAISON_RDV = 'LIVRAISON_RDV',
}

export enum STOCK_STATUS {
    OUT_OF_STOCK = 0,
    IN_STOCK = 1,
    SOON_AVAILABLE = 2,
    SOON_OUT_OF_STOCK = 3,
    ONLY_IN_SHOP = 4,
    PRE_ORDER = 5,
}

export const stockConfig: {
    [key in STOCK_STATUS]: StockConfigType;
} = {
    [STOCK_STATUS.OUT_OF_STOCK]: {
        status: STOCK_STATUS.OUT_OF_STOCK,
        icon: IconName.SHOPPING_CART,
        altIcon: IconName.TIMES_CIRCLE,
        color: IconColor.ERROR,
        label: 'Rupture de stock en ligne,',
        subLabel: 'voir les produits similaires',
        cart: 'Voir les produits similaires',
        hasShipping: false,
        hasCnC: false,
        hasStock: false,
        structuredData: 'OutOfStock',
    },
    [STOCK_STATUS.IN_STOCK]: {
        status: STOCK_STATUS.IN_STOCK,
        icon: IconName.SHOPPING_CART,
        altIcon: IconName.CHECK_CIRCLE,
        color: IconColor.SUCCESS,
        label: 'En stock en ligne,',
        subLabel: 'livré',
        cart: 'Ajouter au panier',
        hasShipping: true,
        hasCnC: true,
        hasStock: true,
        structuredData: 'InStock',
    },
    [STOCK_STATUS.SOON_AVAILABLE]: {
        status: STOCK_STATUS.SOON_AVAILABLE,
        icon: IconName.SHOPPING_CART,
        altIcon: IconName.SHOPPING_CART,
        color: IconColor.WARNING,
        label: 'Bientôt disponible',
        cart: 'Voir les produits similaires',
        hasShipping: false,
        hasCnC: false,
        hasStock: false,
        structuredData: 'OutOfStock',
    },
    [STOCK_STATUS.SOON_OUT_OF_STOCK]: {
        status: STOCK_STATUS.SOON_OUT_OF_STOCK,
        icon: IconName.SHOPPING_CART,
        altIcon: IconName.SHOPPING_CART,
        color: IconColor.SUCCESS,
        label: 'Stock faible en ligne,',
        subLabel: 'livré',
        cart: 'Ajouter au panier',
        hasShipping: true,
        hasCnC: true,
        hasStock: true,
        structuredData: 'InStock',
    },
    [STOCK_STATUS.ONLY_IN_SHOP]: {
        status: STOCK_STATUS.ONLY_IN_SHOP,
        icon: IconName.STORE,
        altIcon: IconName.STORE,
        color: IconColor.ERROR,
        label: 'Rupture de stock en ligne,',
        subLabel: 'voir les produits similaires',
        cart: 'Voir les produits similaires',
        hasShipping: false,
        hasCnC: false,
        hasStock: false,
        structuredData: 'InStoreOnly',
    },
    [STOCK_STATUS.PRE_ORDER]: {
        status: STOCK_STATUS.PRE_ORDER,
        icon: IconName.SHOPPING_CART,
        altIcon: IconName.SHOPPING_CART,
        color: IconColor.WARNING,
        label: 'Disponible en ligne,',
        subLabel: 'livré',
        cart: 'Pré-commander',
        hasShipping: true,
        hasCnC: false,
        hasStock: true,
        structuredData: 'PreOrder',
    },
};
