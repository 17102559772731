import { Session } from 'next-auth';

import { ActiveFiltersType } from '@bytel/product-wall';

import { sapiRepository } from '@repositories/sapi';
import { sapicRepository } from '@repositories/sapic';

import { SapiMergedSapicType, SapiProductChildTypeWithSapicInfo, SapiProductType } from '@app-types/api/sapi';
import {
    SapicCategoryId,
    SapicCustomerCategory,
    SapicFundingMode,
    SapicPriceForFilter,
    SapicPriceForSort,
} from '@app-types/api/sapic';
import { QUOTE_TYPES } from '@app-types/cart';
import { SimulatorCart } from '@app-types/simulator/virtual-cart';

import { transformChildProductToParentProduct } from '@product-detail/helpers';

export type GetProductOptionType = {
    category?: SapicCategoryId;
    sort?: string;
    pageNumber?: number;
    limit?: number;
    clientCategory?: SapicCustomerCategory;
    fundingMode?: SapicFundingMode;
    priceFilter?: SapicPriceForFilter;
    priceSort?: SapicPriceForSort;
    filters?: ActiveFiltersType;
    plan?: string;
    autoComplete?: string;
    renewal?: boolean;
    contract?: string;
    stickers?: string[];
    gencodes?: string[];
    withDetails?: boolean;
    filter?: string;
};

export async function getApiProductDetails(url: string): Promise<SapiProductType> {
    return sapiRepository.getProductByUrl<SapiProductType>(url, true).then((productData) => {
        if ('childs' in productData) {
            return productData;
        } else {
            // Transforme un child en Parent
            // Nécessaire pour transformer un ProductType.ACCESSORY en parent
            return transformChildProductToParentProduct(productData);
        }
    });
}

export async function mergeSapicToSapiProduct(
    sapiProduct: SapiProductType,
    session: Session | null,
): Promise<SapiMergedSapicType> {
    const gencodeList = [sapiProduct.gencode, ...sapiProduct.product_ids];

    const params: GetProductOptionType = {
        limit: gencodeList.length,
        gencodes: gencodeList,
    };

    const virtualCart: SimulatorCart = {
        cart: {
            quotes: [
                {
                    type: QUOTE_TYPES.ACQUISITION,
                    isCurrent: true,
                    products: [],
                },
            ],
        },
    };

    return await sapicRepository.getProducts(virtualCart, params, session).then(({ products }) => {
        const childsWithSapicList: SapiProductChildTypeWithSapicInfo[] = Object.values(sapiProduct.childs).map(
            (child) => ({
                ...child,
                productSapic: products.find((sapicChild) => sapicChild.gencode === child.gencode),
            }),
        );
        return {
            ...sapiProduct,
            productSapic: products[0],
            childs: Object.fromEntries(childsWithSapicList.map((child) => [child.gencode, child])),
        };
    });
}
