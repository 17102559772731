export const isFirstLetterVowel = (word: string): boolean => {
    return word.match(/^[aeiou]/i) !== null;
};

export const capitalize = (s?: string): string => {
    if (!s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatPrice = (amount: number) => {
    return amount?.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: Number.isInteger(amount) ? 0 : 2,
    });
};
